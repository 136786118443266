import { extendVariants, Button as NextUIButton } from "@heroui/react";
import { generateTrackName } from "core/validationSchemas/utils";
import { Spinner } from "ds/ui";
import { colorVariants } from "ds/utils";
import { forwardRef, LegacyRef, ReactElement } from "react";
import { useTracking } from "react-tracking";
import { useTranslations } from "translations";

const ExtendedButton = extendVariants(NextUIButton, {
  variants: {
    variant: {
      solid:
        "data-[disabled=true]:bg-gray-200 !outline-none-v3 data-[focus-visible]:outline-focus-visible hover:no-underline",
      bordered:
        "border-small bg-transparent !outline-none-v3 data-[focus-visible]:outline-focus-visible data-[disabled=true]:bg-gray-200 hover:no-underline",
      light:
        "!font-semibold !gap-2 bg-transparent !outline-none-v3 data-[focus-visible]:outline-focus-visible data-[disabled=true]:bg-gray-200 hover:no-underline",
      faded:
        "data-[disabled=true]:bg-gray-200 data-[disabled=true]:border-gray-300 border-small !outline-none-v3 data-[focus-visible]:outline-focus-visible hover:no-underline",
      ghost:
        "data-[disabled=true]:bg-gray-200 data-[disabled=true]:border-gray-300 border-small bg-transparent !outline-none-v3 data-[focus-visible]:outline-focus-visible hover:no-underline",
    },
    size: {
      xs: "font-medium px-3 py-3 min-w-16 h-8 text-tiny gap-2 rounded-small [&>svg]:w-4 [&>svg]:!stroke-sm",
      sm: "font-medium px-3 py-3 min-w-16 h-8 text-small gap-2 rounded-small [&>svg]:w-4 [&>svg]:!stroke-sm",
      md: "font-medium px-3 py-4 min-w-20 h-10 text-small gap-3 rounded-small [&>svg]:w-4 [&>svg]:!stroke-md",
      lg: "font-medium px-3 py-4 min-w-24 h-12 text-medium gap-3 rounded-small [&>svg]:w-5 [&>svg]:!stroke-lg",
    },
    color: {
      default:
        "!opacity-100 data-[disabled=true]:text-default-disabled data-[hover=true]:!opacity-100",
      primary:
        "!opacity-100 data-[disabled=true]:text-default-disabled data-[hover=true]:!opacity-100",
      success:
        "!opacity-100 data-[disabled=true]:text-default-disabled data-[hover=true]:!opacity-100",
      warning:
        "!opacity-100 data-[disabled=true]:text-default-disabled data-[hover=true]:!opacity-100",
      danger:
        "!opacity-100 data-[disabled=true]:text-default-disabled data-[hover=true]:!opacity-100",
      black: "data-[disabled=true]:text-default-disabled",
      white: "data-[disabled=true]:text-default-disabled",
    },
    fullWidth: {
      true: "w-full",
    },
    isDisabled: {
      true: "pointer-events-none",
    },
    isInGroup: {
      true: "[&:not(:first-child):not(:last-child)]:rounded-none",
    },
    isIconOnly: {
      true: "px-0 !gap-0",
      false: "",
    },
    disableAnimation: {
      true: "!transition-none data-[pressed=true]:scale-100",
      false:
        "transition-transform-colors-opacity motion-reduce:transition-none",
    },
  },
  defaultVariants: {
    size: "md",
    variant: "solid",
    color: "default",
    fullWidth: false,
    isDisabled: false,
    isInGroup: false,
  },
  compoundVariants: [
    // solid / color
    {
      variant: "solid",
      color: "default",
      class: [
        colorVariants.solid.default,
        "data-[hover=true]:bg-primary-dark data-[hover=true]:text-primary-dark-foreground",
      ],
    },
    {
      variant: "solid",
      color: "primary",
      class: [
        colorVariants.solid.primary,
        "data-[hover=true]:bg-primary-dark data-[hover=true]:text-primary-dark-foreground",
      ],
    },
    {
      variant: "solid",
      color: "success",
      class: [
        colorVariants.solid.success,
        "data-[hover=true]:bg-success-dark data-[hover=true]:text-primary-dark-foreground",
      ],
    },
    {
      variant: "solid",
      color: "warning",
      class: [
        colorVariants.solid.warning,
        "data-[hover=true]:bg-warning-dark data-[hover=true]:text-warning-dark-foreground",
      ],
    },
    {
      variant: "solid",
      color: "danger",
      class: [colorVariants.solid.danger, "data-[hover=true]:bg-danger-dark"],
    },
    {
      variant: "solid",
      color: "black",
      class: ["bg-black", "text-white", "data-[hover=true]:bg-grey-700"],
    },
    {
      variant: "solid",
      color: "white",
      class: [
        "bg-white",
        "text-black",
        "data-[hover=true]:bg-white-100 data-[focus-visible]:ring-1 data-[focus-visible]:ring-offset-1 data-[focus-visible]:ring-white",
      ],
    },
    // shadow / color
    {
      variant: "shadow",
      color: "default",
      class: colorVariants.shadow.default,
    },
    {
      variant: "shadow",
      color: "primary",
      class: colorVariants.shadow.primary,
    },
    {
      variant: "shadow",
      color: "success",
      class: colorVariants.shadow.success,
    },
    {
      variant: "shadow",
      color: "danger",
      class: colorVariants.shadow.danger,
    },
    // bordered / color
    {
      variant: "bordered",
      color: "default",
      class: colorVariants.bordered.default,
    },
    {
      variant: "bordered",
      color: "primary",
      class: colorVariants.bordered.primary,
    },
    {
      variant: "bordered",
      color: "success",
      class: colorVariants.bordered.success,
    },
    {
      variant: "bordered",
      color: "danger",
      class: colorVariants.bordered.danger,
    },
    // flat / color
    {
      variant: "flat",
      color: "default",
      class: colorVariants.flat.default,
    },
    {
      variant: "flat",
      color: "primary",
      class: colorVariants.flat.primary,
    },
    {
      variant: "flat",
      color: "success",
      class: colorVariants.flat.success,
    },
    {
      variant: "flat",
      color: "danger",
      class: colorVariants.flat.danger,
    },
    // faded / color
    {
      variant: "faded",
      color: "default",
      class: [
        colorVariants.faded.default,
        "data-[hover=true]:bg-default-light",
      ],
    },
    {
      variant: "faded",
      color: "primary",
      class: [
        colorVariants.faded.primary,
        "data-[hover=true]:bg-primary-light",
      ],
    },
    // light / color
    {
      variant: "light",
      color: "default",
      class: [
        colorVariants.light.default,
        "data-[hover=true]:bg-default-light data-[hover=true]:text-default-dark",
      ],
    },
    {
      variant: "light",
      color: "primary",
      class: [
        colorVariants.light.primary,
        "data-[hover=true]:bg-primary-light data-[hover=true]:text-primary-dark",
      ],
    },
    {
      variant: "light",
      color: "success",
      class: [
        colorVariants.light.success,
        "data-[hover=true]:bg-success-light data-[hover=true]:text-success-dark",
      ],
    },
    {
      variant: "light",
      color: "warning",
      class: [
        colorVariants.light.warning,
        "text-warning-dark",
        "data-[hover=true]:bg-warning-light data-[hover=true]:text-warning-dark",
      ],
    },
    {
      variant: "light",
      color: "danger",
      class: [
        colorVariants.light.danger,
        "data-[hover=true]:bg-danger-light data-[hover=true]:text-danger-dark",
      ],
    },
    {
      variant: "light",
      color: "black",
      class: [
        "text-black",
        "data-[hover=true]:bg-opacity-15 data-[hover=true]:bg-black",
        "disabled:bg-gray-200",
      ],
    },
    {
      variant: "light",
      color: "white",
      class: [
        "text-white",
        "data-[hover=true]:bg-opacity-15 data-[hover=true]:bg-white",
        "disabled:bg-gray-200",
      ],
    },
    // ghost / color
    {
      variant: "ghost",
      color: "default",
      class: [colorVariants.ghost.default, "data-[hover=true]:!bg-default"],
    },
    {
      variant: "ghost",
      color: "primary",
      class: [
        colorVariants.ghost.primary,
        "data-[hover=true]:!bg-primary data-[hover=true]:!text-primary-foreground",
      ],
    },
    {
      variant: "ghost",
      color: "success",
      class: [
        colorVariants.ghost.success,
        "data-[hover=true]:!bg-success data-[hover=true]:!text-success-foreground",
      ],
    },
    {
      variant: "ghost",
      color: "warning",
      class: [
        colorVariants.ghost.warning,
        "text-warning-dark border-warning-dark",
        "data-[hover=true]:!bg-warning-dark data-[hover=true]:!text-warning-dark-foreground",
      ],
    },
    {
      variant: "ghost",
      color: "danger",
      class: [
        colorVariants.ghost.danger,
        "data-[hover=true]:!bg-danger data-[hover=true]:!text-danger-foreground",
      ],
    },
    {
      variant: "ghost",
      color: "black",
      class: [
        "bg-white text-black border-black",
        "data-[hover=true]:!bg-black data-[hover=true]:!text-white",
      ],
    },
    {
      isIconOnly: true,
      size: "xs",
      class: "min-w-8 w-8 h-8 [&>svg]:w-4",
    },
    {
      isIconOnly: true,
      size: "sm",
      class: "min-w-8 w-8 h-8",
    },
    {
      isIconOnly: true,
      size: "md",
      class: "min-w-10 w-10 h-10",
    },
    {
      isIconOnly: true,
      size: "lg",
      class: "min-w-12 w-12 h-12",
    },
  ],
});

type ExtendedButtonProps = React.ComponentProps<typeof ExtendedButton>;

type Variant = Extract<
  ExtendedButtonProps["variant"],
  "ghost" | "faded" | "solid" | "light"
>;

type Color = Extract<
  ExtendedButtonProps["color"],
  "primary" | "success" | "warning" | "danger" | "black" | "white"
>;

export type ButtonProps = Omit<
  ExtendedButtonProps,
  "variant" | "color" | "id" | "onClick"
> & {
  color?: Color;
  id: string;
  onClick?: never; // @deprecated please use onPress instead
  variant?: Variant;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const { trackEvent } = useTracking();
    const translations = useTranslations();

    return (
      <ExtendedButton
        {...props}
        ref={ref as LegacyRef<ReactElement>}
        data-testid={props.id}
        endContent={props.isLoading ? undefined : props.endContent}
        onPress={(e) => {
          trackEvent({ name: generateTrackName({ id: props.id }) });
          props.onPress?.(e);
        }}
        role={props.as === "a" ? "link" : "button"}
        spinner={
          <Spinner
            role="progressbar"
            aria-valuemin={0}
            aria-valuemax={100}
            aria-valuetext={translations.general.loading}
            size={props.size !== "lg" ? "xs" : "sm"}
          />
        }
        startContent={props.isLoading ? undefined : props.startContent}
      />
    );
  },
);

export const BUTTON_DISPLAY_NAME = "RecareUI.Button";
Button.displayName = BUTTON_DISPLAY_NAME;
