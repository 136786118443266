export const LOCAL_STORAGE_KEYS = {
  TRACKING_SESSION: "trackingSession",
  VERSION_CHECKER: "versionChecker",
  CHALLENGE_DATETIME: "challenge_datetime",
} as const;

export function getObjectLocalStorage<T extends AnyObject>(
  key: string,
): T | undefined {
  try {
    const data = localStorage.getItem(key);

    if (!data) return undefined;

    return JSON.parse(data) as T;
  } catch (err) {
    console.error(err);
  }
}

export function setObjectLocalStorage<T extends AnyObject>(
  key: string,
  value: T,
) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function getObjectOrDefaultLocalStorage<T extends AnyObject>(
  key: string,
  defaultValue: T,
): T {
  let storedValue = localStorage.getItem(key);

  if (!storedValue) {
    console.info(
      `No value for key '${key}' from localStorage. Setting default value`,
    );
    storedValue = JSON.stringify(defaultValue);
    localStorage.setItem(key, storedValue);
  } else {
    try {
      return JSON.parse(storedValue) as T;
    } catch (error) {
      console.error(`Error parsing value for key '${key}' from localStorage.`);
    }
  }

  return defaultValue;
}
